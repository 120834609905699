import styled from '@emotion/styled';
import { TextInput, Radio, Button, Group, Box, Switch, Textarea, Select, JsonInput, Image, FileInput, Center, ActionIcon, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Text } from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Page } from '../page';
import { useAppContext } from '../../core/context';
import { backend } from '../../core/backend';
import { useNavigate, useParams } from 'react-router-dom';
import { Story } from '../../core/types';
import { defaultVoiceList as defaultOpenAIVoiceList } from '../../tts-plugins/openaitts-defaults';
import { defaultVoiceList as defaultElevenLabsVoiceList } from '../../tts-plugins/elevenlabs-defaults';
import ChapterTree from './chapter-tree';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: ;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
    overflow-y: auto ; /* Enable vertical scrolling */
`;

// longFormStats is an array of the form [{name: "stat1", value: "value1"}, ...]
// shortFormStats is an Object of the form {"stat1": "value1", ...}
function shortFormStats(longFormStats: any) {
  if (!longFormStats || longFormStats.length === 0) {
    return undefined;
  }

  const stats = {};
  longFormStats.forEach((stat) => {
    stats[stat.name] = stat.value;
  });

  return stats;
}

function longFormStats(shortFormStats: any) {
  if (!shortFormStats) {
    return [];
  }

  return Object.keys(shortFormStats).map((key) => ({ name: key, value: shortFormStats[key] }));
}

export default function StoryEditor() {
  // get story name from the :name parameter
  const { name, version } = useParams<string>();
  // let version : string | undefined = undefined;

  // if name includes a version, split it
  // if (name && name.includes('/')) {
  //   const parts = name.split('/');
  //   name = parts[0];
  //   version = parts[1];
  // }

  // the story to edit with this specific version
  const [story, setStory] = useState<Story | undefined>(undefined);

  // the stories with the same name but various versions
  const [storiesMap, setStoriesMap] = useState<Map<string, Story>>(new Map<string, Story>());
  const [okMessage, setOkMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const fetchStory = useCallback(async () => {
    setOkMessage(undefined);
    setErrorMessage(undefined);

    if (name) {
      // fetch story from backend
      try {
        // we get all stories with the same name regardless of version
        const result = await backend.current?.getStory(name, undefined);
        if (result) {
          setStoriesMap(result);

          // if a version is specified, get the story with that version
          if (version) {
            setStory(result.get(version));
          } else {
            // otherwise, get the latest version
            const versions = Array.from(result.keys());
            if (versions.length > 0) {
              setStory(result.get(versions[versions.length - 1]));
            }
          }
        }
      } catch (error: any) {
        setStory(undefined);
        setStoriesMap(new Map<string, Story>());
        setErrorMessage(error.message);
      }
    }
  }, [name, version]);

  useEffect(() => {
    fetchStory();
  }, [name, version]);

  const context = useAppContext();
  const navigate = useNavigate();

  const [coverFileUpload, setCoverFileUpload] = useState<File | null>(null);
  const [themeFileUpload, setThemeFileUpload] = useState<File | null>(null);

  // const { authenticated } = useAppContext();

  // const [modalOpen, setModalOpen] = useState(false);
  // const [modalContent, setModalContent] = useState('');
  // const [modalTitle, setModalTitle] = useState('');

  // const openModal = (content, title) => {
  //   setModalContent(content);
  //   setModalTitle(title);
  //   setModalOpen(true);
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  // //for signIn
  // const signIn = useCallback(() => {
  //   if ((window as any).AUTH_PROVIDER !== 'local') {
  //     backend.current?.signIn();
  //   } else {
  //     dispatch(openLoginModal());
  //   }
  // }, [dispatch])

  const form = useForm<Story>({
    initialValues: story,

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    if (story) {
      form.setValues({
        _id: story._id,
        name: story.name,
        title: story.title,
        category: story.category,
        version: story.version,
        type: story.type,
        prompt: story.prompt,
        contentRating: story.contentRating,
        author: story.author || '',
        description: story.description || '',
        cover: story.cover || '',
        themeMusic: story.themeMusic || '',
        voice: story.voice || [],
        stats: longFormStats(story.stats),
      });
      form.resetDirty(story);
      setErrorMessage(undefined);
      setOkMessage(undefined);
    }
  }, [story]);

  const [versions, setVersions] = useState<any[]>([]);

  useEffect(() => {
    if (storiesMap) {
      setVersions(Array.from(storiesMap.keys()).map((version) => ({ value: version, label: version })));
    }
  }, [storiesMap]);

  const maturityRatings = [
    { label: "Appropriate for all ages", value: "0" },
    { label: "7+", value: "7" },
    { label: "13+", value: "13" },
    { label: "16+", value: "16" },
    { label: "18+", value: "18" },
  ];

  const [webSpeechVoices, setWebSpeechVoices] = useState<any>([]);

  useEffect(() => {
    // get web-speech available voices
    const voices = window.speechSynthesis.getVoices().map(v => ({
      service: 'web-speech',
      id: v.name,
      name: v.name,
    }));

    if (voices) {
      setWebSpeechVoices(voices);
    }
  }, []);

  const updateStory = useCallback(async (updatedStory: Story) => {
    try {
      if (coverFileUpload) {
        const mediaURL = await backend.current?.uploadMedia(coverFileUpload);
        updatedStory.cover = mediaURL;
      }

      if (themeFileUpload) {
        const themeURL = await backend.current?.uploadMedia(themeFileUpload);
        updatedStory.themeMusic = themeURL;
      }

      const mutatedStory = { ...updatedStory };
      mutatedStory.stats = shortFormStats(updatedStory.stats);

      const result = await backend.current?.updateStory(mutatedStory);

      setOkMessage(result.message);
      navigate('/story/' + updatedStory.name + '/' + updatedStory.version + '/');

      // refetch story to get the updated version
      await fetchStory();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [story, errorMessage, coverFileUpload]);

  const createNewVersion = useCallback(async (updatedStory: Story, newVersion: string) => {
    // new story has newVersion and omits the _id
    const newStory = { ...updatedStory, version: newVersion, _id: undefined };

    try {
      const result = await backend.current?.updateStory(newStory);
      setOkMessage(result.message);
      navigate('/story/' + newStory.name + '/' + newStory.version + '/');

      // refetch story to get the updated version
      // await fetchStory();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [story, errorMessage]);

  const purgeStory = useCallback(async (story: Story | undefined) => {
    const id = story?._id;

    if (!id) {
      return;
    }

    // show confirmation dialog
    if (!window.confirm(`Are you sure you want to purge the story ${story.name}/${story.version} ?`)) {
      return;
    }

    try {
      const result = await backend.current?.purgeStory(id);
      setOkMessage(result.message);
      navigate('/');

    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [story, errorMessage]);

  const onChapterTreeToggle = useCallback(async (story: Story | undefined) => {
    if (story) {
      context.setChapterTreeMode(!context.chapterTreeMode);
    }
  }, [story, context.chapterTreeMode, context.setChapterTreeMode]);


  const onVersionChange = useCallback((version: string) => {
    form.setFieldValue('version', version);
    navigate(`/story/${form.values.name}/${version}/`);
    fetchStory();
  }, [form, story, version, navigate]);

  const onCensorChange = useCallback(async (censored: boolean) => {
    if (story) {
      try {
        const result = await backend.current?.censorStory(story.name, story.version, censored);
        setOkMessage(result.message);
        fetchStory();
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  }, [story, errorMessage, okMessage, fetchStory]);

  const statsList = useMemo(() => {
    let formStats = form.values?.stats;
    if (!formStats) {
      formStats = [];
    }

    return formStats.map((_, index) => (
      <Draggable key={index} index={index} draggableId={index.toString()}>
        {(provided) => (
          <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
            <Center {...provided.dragHandleProps}>
              <i className="fas fa-grip-vertical"></i>
            </Center>
            <TextInput placeholder="Stat name" {...form.getInputProps(`stats.${index}.name`)} />
            <TextInput
              placeholder="Initial Value"
              {...form.getInputProps(`stats.${index}.value`)}
            />
            <ActionIcon onClick={() => form.removeListItem('stats', index)}>
              <i className="fa fa-times" />
            </ActionIcon>
          </Group>
        )}
      </Draggable>
    ));
  }, [form.values?.stats]);

  // TODO: add onClick handler for voices that when their radio is selected plays a sample of the voice

  const storyForm = useMemo(() => {
    if (!story) return null;

    return (
      <>
        <h1> <FormattedMessage defaultMessage={'Story Editor'} /></h1>
        <form onSubmit={form.onSubmit((story) => updateStory(story))}>
          <Group position="right" mt="md">
            <Switch {...form.getInputProps('censored')} checked={story.censored} label="Censored" onChange={(event) => onCensorChange(event.currentTarget.checked)} />
            <Button type="submit">Update</Button>
            <Button onClick={() => purgeStory(story)} disabled={!story?._id} >Purge</Button>
            {!context.chapterTreeMode && <Button onClick={() => onChapterTreeToggle(story)} disabled={!story?._id || story.type !== 's'} >Chapter Tree</Button>}
            {context.chapterTreeMode && <Button onClick={() => onChapterTreeToggle(story)} disabled={!story?._id} >Back to Story</Button>}
          </Group>
          <Group position="left" mt="md">
            <TextInput {...form.getInputProps('name')} label="Name" required disabled />
            <Select
              label="Version"
              data={versions}
              onChange={onVersionChange}
              value={story.version}
              searchable
              creatable
              getCreateLabel={(newVersion) => `+ Add ${newVersion}`}
              onCreate={(newVersion) => {
                const item = { value: newVersion, label: newVersion };
                setVersions((current) => [...current, item]);
                createNewVersion(story, newVersion);
                return item;
              }}

            />
            <TextInput {...form.getInputProps('_id')} label="ID" required disabled />
          </Group>
          <h4>Basic info</h4>
          <TextInput {...form.getInputProps('title')} label="Title" required />
          <TextInput {...form.getInputProps('author')} label="Author" required />
          <TextInput {...form.getInputProps('category')} label="Category" required />
          <Radio.Group
            {...form.getInputProps('type')}
            label="Type"
            required
          >
            <Radio value='s' label='Structured' />
            <Radio value='u' label='Unstructured' />
          </Radio.Group>
          <Radio.Group
            {...form.getInputProps('contentRating')}
            label="Content Rating"
            required
          >
            {maturityRatings.map((rating) => (
              <Radio key={rating.value} value={rating.value} label={rating.label} />
            ))}
          </Radio.Group>
          <Textarea {...form.getInputProps('prompt')} label="Prompt" required autosize maxRows={10} />
          <Textarea {...form.getInputProps('description')} label="Description" autosize maxRows={2} />
          <Group>
            <TextInput {...form.getInputProps('cover')} label="Cover image (webp file)" style={{ width: '50%' }} />
            <FileInput
              value={coverFileUpload}
              label="Upload"
              accept="image/webp"
              icon={<i className="fa-solid fa-file-arrow-up" />}
              onChange={setCoverFileUpload}
              style={{ width: '40%' }}
            />
            <Image src={story.cover ? context.appBaseURL + story.cover : undefined} width="50%" />
          </Group>
          <Group>
            <TextInput placeholder="" {...form.getInputProps('themeMusic')} label="Theme Music (mp3 or wav)" style={{ width: '70%' }} rightSection={<audio controls src={story.themeMusic ? context.appBaseURL + story.themeMusic : undefined} style={{ width: '100%' }} />} rightSectionWidth={"30%"} />

            <FileInput
              value={themeFileUpload}
              label="Upload"
              accept="image/webp"
              icon={<i className="fa-solid fa-file-arrow-up" />}
              onChange={setThemeFileUpload}
              style={{ width: '10%' }}
            />
          </Group>
          <h4>Text-to-speech Voices</h4>
          <Group>
            <Select
              {...form.getInputProps('voice.openaitts')}
              label="OpenAI Voice"
              required
              data={defaultOpenAIVoiceList.map((voice) => ({ value: voice.voice_id, label: voice.name }))}
            />

            <Select
              {...form.getInputProps('voice.web-speech')}
              label="Web-Speech Voice"
              data={[{ value: '', label: 'Default' }, ...webSpeechVoices.map((voice) => ({ value: voice.id, label: voice.name }))]}
            />

            <Select
              {...form.getInputProps('voice.elevenlabs')}
              label="ElevenLabs Voice"
              required
              data={defaultElevenLabsVoiceList.map((voice) => ({ value: voice.voice_id, label: voice.name }))}
            />
          </Group>
          <h4>Stats</h4>
          {/* NOTE: droppable has issues with draggable elements when in react strict mode and devlopment
           See: https://github.com/atlassian/react-beautiful-dnd/issues/2396
           For development, disable react strict mode in index.tsx
           */}
          <DragDropContext
            onDragEnd={({ destination, source }) => 
              form.reorderListItem('stats', { from: source.index, to: destination.index })
            }
          >
            <Droppable droppableId="stats-dnd-list" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {statsList}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Group position="center" mt="md">
            <Button onClick={() => form.insertListItem('stats', { name: '', value: '' })}>
              Add a stat
            </Button>
          </Group>
        </form>
      </>);
  }, [story, form, statsList]);

  const treeHeader = useMemo(() => {
    if (!story) return null;

    return <div>
      <h1>Chapter Tree</h1>
      <h2>Story: {story.name} - Version: {story.version}</h2>
    </div>;
  }, [story]);

  const treeEditor = useMemo(() => {
    if (!story) return null;

    return <div>
      {treeHeader}
      {!context.chapterTreeMode && <ChapterTree story={story} />}
    </div>;
  }, [story]);

  return <Page id={'story-editor'} showSubHeader={true}>
    <Container>
      <Box sx={{ width: '90%' }} mx="auto">
        {okMessage && <Alert color="blue" title="OK">{okMessage}</Alert>}
        {errorMessage && <Alert color="red" title="Error">{errorMessage}</Alert>}
        {!story && <h1> <FormattedMessage defaultMessage={'Choose a story from the sidebar to edit'} /></h1>}
        {story && !context.chapterTreeMode && storyForm}
        {story && context.chapterTreeMode && treeEditor}
      </Box>
    </Container>
  </Page>;
}
