export const defaultVoiceList = [
    {
        "voice_id": "alloy",
        "name": "Alloy",
        "preview_url": "https://cdn.openai.com/API/docs/audio/alloy.wav",
    },
    {
        "voice_id": "echo",
        "name": "Echo",
        "preview_url": "https://cdn.openai.com/API/docs/audio/echo.wav",
    },
    {
        "voice_id": "onyx",
        "name": "Onyx",
        "preview_url": "https://cdn.openai.com/API/docs/audio/onyx.wav",
    },
    {
        "voice_id": "fable",
        "name": "Fable",
        "preview_url": "https://cdn.openai.com/API/docs/audio/fable.wav",
    },
    {
        "voice_id": "nova",
        "name": "Nova",
        "preview_url": "https://cdn.openai.com/API/docs/audio/nova.wav",
    },
    {
        "voice_id": "shimmer",
        "name": "Shimmer",
        "preview_url": "https://cdn.openai.com/API/docs/audio/shimmer.wav",
    },
];

export const defaultOpenAITTSVoiceID = defaultVoiceList.find(voice => voice.name === "Fable")!.voice_id;