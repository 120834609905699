import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeModals, selectModal } from '../../store/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Group, Modal, Space, Textarea } from '@mantine/core';
import { backend } from '../../core/backend';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../core/context';
import { set } from 'idb-keyval';

const Container = styled.form`
    * {
        font-family: "Work Sans", sans-serif;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .mantine-TextInput-root, .mantine-PasswordInput-root {
        margin-top: 1rem;
    }
    
    .mantine-TextInput-root + .mantine-Button-root,
    .mantine-PasswordInput-root + .mantine-Button-root {
        margin-top: 1.618rem;
    }

    .mantine-Button-root {
        margin-top: 1rem;
    }

    label {
        margin-bottom: 0.25rem;
    }
`;

const emailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function validateEmail(email: string) {
    return emailRE.test(email);
}

export function InviteUsersModal(props: any) {
    const modal = useAppSelector(selectModal);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const context = useAppContext();

    const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);
    // const onCreateStoryClick = useCallback(() => dispatch(openInviteModal()), [dispatch]);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [okMessage, setOkMessage] = useState<string | null>(null);

    useEffect(() => {
        setErrorMessage(null);
    }, [modal]);

    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage(null);

        const form = event.currentTarget;
        const formData = new FormData(form);

        const emailsCSV = formData.get('emails') as string;
        const emails = emailsCSV.split(',').map((email) => email.trim());

        // validate emails
        for (const email of emails) {
            if (!validateEmail(email)) {
                setErrorMessage(`Invalid email: ${email}`);
                return false;
            }
        }

        const result = await backend.current?.inviteUsers(emails);

        if (result?.status === 'ok' && result.ninvited > 0) {
            // After form submission, redirect to another page:
            // navigate('/user');
            setOkMessage(`Invited ${result.ninvited} users`);
            setErrorMessage(null);
        } else {
            setOkMessage(null);
            setErrorMessage(`Failed to invite users. Do they already exist? ${result?.message || ''}`);
        }

        // dispatch(closeModals())

    }, [navigate, dispatch]);

    return <Modal opened={modal === 'invite'} onClose={onClose} withCloseButton={false}>
        <Container method="post" onSubmit={handleSubmit}>
            <h2>
                <FormattedMessage defaultMessage={"Invite new users"} />
            </h2>
            {okMessage && <Alert title="Success" color="blue" withCloseButton onClose={() => setOkMessage(null)}>{okMessage}</Alert>}
            {errorMessage && <Alert title="Error" color="red" withCloseButton onClose={() => setErrorMessage(null)}>{errorMessage}</Alert>}
            <Textarea
                placeholder="Comma-separated list of emails"
                label="Emails of users to invite"
                name="emails"
                withAsterisk
            />
            <Group>
            <Button type="submit">
                <FormattedMessage defaultMessage={"Invite"} />
            </Button>
            <Button onClick={onClose}>
                <FormattedMessage defaultMessage={"Close"} />
            </Button>
            </Group>
        </Container>
    </Modal>
}