import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../core/context';
import { useAppDispatch } from '../../store';
import { toggleSidebar } from '../../store/sidebar';
import { ActionIcon, Avatar, Button, Col, Loader, Menu, Paper, Textarea, Tooltip, Space } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { backend } from '../../core/backend';
import { Auth0User } from '../../core/types';

const Container = styled.div`
    margin: calc(1.618rem - 1rem);
    margin-top: -0.218rem;
`;

const Empty = styled.p`
    text-align: center;
    font-size: 0.8rem;
    padding: 2rem;
`;

const UserList = styled.div``;

const UserListItemLink = styled(Link)`
    display: block;
    position: relative;
    padding: 0.4rem 1rem;
    margin: 0.218rem 0;
    line-height: 1.7;
    text-decoration: none;
    border-radius: 0.25rem;

    &:hover, &:focus, &:active {
        background: rgba(0, 0, 0, 0.1);
    }

    &.selected {
        background: #2b3d54;
    }

    strong {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.6;
        padding-right: 1rem;
        color: white;
    }

    p {
        font-size: 0.8rem;
        font-weight: 200;
        opacity: 0.8;
    }

    .mantine-ActionIcon-root {
        position: absolute;
        right: 0.0rem;
        top: 50%;
        margin-top: -22px;
        opacity: 0;
    }

    &:hover {
        .mantine-ActionIcon-root {
            opacity: 1;
        }
    }
`;

function UserListItem(props: { user: Auth0User, onClick: any, selected: boolean, onRefresh: any }) {
    const c = props.user;
    const modals = useModals();
    const navigate = useNavigate();

    const onDelete = useCallback((e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        modals.openConfirmModal({
            title: "Are you sure you want to delete this user?",
            children: <p style={{ lineHeight: 1.7 }}>The user "{c.name}" will be permanently deleted. This cannot be undone.</p>,
            labels: {
                confirm: "Delete permanently",
                cancel: "Cancel",
            },
            confirmProps: {
                color: 'red',
            },
            onConfirm: async () => {
                try {
                    await backend.current?.deleteUser(c.user_id);
                    await props.onRefresh();
                    navigate('/user');
                } catch (e) {
                    console.error(e);
                    modals.openConfirmModal({
                        title: "Something went wrong",
                        children: <p style={{ lineHeight: 1.7 }}>The user "{c.name}" could not be deleted.</p>,
                        labels: {
                            confirm: "Try again",
                            cancel: "Cancel",
                        },
                        onConfirm: () => onDelete(),
                    });
                }
            },
        });
    }, [c.name, c.user_id]);

    // const onRename = useCallback((e: React.MouseEvent) => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     // Display a modal with a TextInput
    //     modals.openModal({
    //         title: "Rename user",
    //         children: <div>
    //             <Textarea
    //                 id="user-title"
    //                 defaultValue={c.title}
    //                 maxLength={500}
    //                 autosize
    //                 required />
    //             <Button
    //                 fullWidth
    //                 variant="light"
    //                 style={{ marginTop: '1rem' }}
    //                 onClick={() => {
    //                     const title = document.querySelector<HTMLInputElement>('#user-title')?.value?.trim();
    //                     // const ygame = context.game.doc.getYUser(c.name);
    //                     // if (yuser && title && title !== yuser?.title) {
    //                     //     yuser.title = title;
    //                     // }
    //                     modals.closeAll();
    //                 }}
    //             >
    //                 Save changes
    //             </Button>
    //         </div>,
    //     });
    // }, [c.name, c.title]);

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpen(open => !open);
    }, []);

    const userFirstLetter = c.name ? c.name.charAt(0).toUpperCase() : 'X';
    
    return (
        <UserListItemLink to={'/user/' + c.user_id + '/'}
            onClick={props.onClick}
            data-user-id={c.name}
            className={props.selected ? 'selected' : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip multiline
                    label={c.name || <FormattedMessage defaultMessage={"Untitled"} description="default user name for untitled users" />}>
                    <Avatar
                        src={c.picture}
                        alt={c.name}
                        size="md"
                        color="red"
                    >{userFirstLetter}
                    </Avatar>
                </Tooltip>
                <Space w="md" />
                <strong>{c.name}</strong>
            </div>
            <Menu opened={menuOpen}
                closeOnClickOutside={true}
                closeOnEscape={true}
                onClose={() => setMenuOpen(false)}>
                <Menu.Target>
                    <ActionIcon size="xl" onClick={toggleMenu}>
                        <i className="fas fa-ellipsis" />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    {/* <Menu.Item onClick={onRename} icon={<i className="fa fa-edit" />}>
                        <FormattedMessage defaultMessage={"Rename this user"} />
                    </Menu.Item>
                    <Menu.Divider /> */}
                    <Menu.Item onClick={onDelete} color="red" icon={<i className="fa fa-trash" />}>
                        <FormattedMessage defaultMessage={"Delete this user"} />
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </UserListItemLink>
    );
}

export default function Users(props: any) {
    const context = useAppContext();
    const dispatch = useAppDispatch();

    const { id: currentUserID } = useParams();
    const [users, setUsers] = useState<Auth0User[]>([]);
    const [synced, setSynced] = useState<boolean>(false);

    const fetchUsers = useCallback(async () => {
        const result = await backend.current?.getUsers();

        // sort by email
        result.sort((a: Auth0User, b: Auth0User) => a.name.localeCompare(b.name));

        setUsers(result);
        setSynced(true);
    }, [backend.current, users, currentUserID]);

    const onRefresh = useCallback(async () => {
        setUsers([]);
        await fetchUsers();
    }, [backend.current, users, currentUserID]);

    useEffect(
        () => {
            if (context.authenticated) {
                fetchUsers();
            }
        }, [context.authenticated, currentUserID]
    )

    const onClick = useCallback((e: React.MouseEvent) => {
        if (e.currentTarget.closest('button')) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (window.matchMedia('(max-width: 40em)').matches) {
            dispatch(toggleSidebar());
        }
    }, [dispatch]);

    useEffect(() => {
        if (currentUserID) {
            const el = document.querySelector(`[data-user-id="${currentUserID}"]`);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, [currentUserID]);

    // const synced = !backend.current || backend.current?.isSynced();

    return (
        <Container>
            <Button onClick={onRefresh} variant="light" color="gray"><li className="fas fa-sync" /></Button>
            {users.length > 0 && <UserList>
                {users.map(c => (
                    <UserListItem key={c.user_id} user={c} onClick={onClick} selected={c.user_id === currentUserID}
                        onRefresh={onRefresh} />
                ))}
            </UserList>}
            {users.length === 0 && !synced && <Empty>
                <Loader size="sm" variant="dots" />
            </Empty>}
            {users.length === 0 && synced && <Empty>
                <FormattedMessage defaultMessage={"No users found."} description="Message shown on the User Hiuser screen for new users who haven't started their first user session" />
            </Empty>}
        </Container>
    );
}