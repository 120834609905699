import styled from '@emotion/styled';
import { Button, Modal } from '@mantine/core';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../store';
import { Page } from '../page';
// import { useOption } from '../../core/options/use-option';
// import { isProxySupported } from '../../core/game/openai';
import { useAppContext } from '../../core/context';
// import Terms from './terms';
// import Privacy from './privacy';
// //for login button
import { backend } from '../../core/backend';
import { openLoginModal } from '../../store/ui';
// import MessageError from '../message-error';
// import { StoriesCarousel } from '../stories-carousel';
///

const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: ;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
    overflow-y: auto ; /* Enable vertical scrolling */
`;

export default function LandingPage(props: any) {
  const dispatch = useAppDispatch();
  const { authenticated } = useAppContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const openModal = (content, title) => {
    setModalContent(content);
    setModalTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  //for signIn
  const signIn = useCallback(() => {
    if ((window as any).AUTH_PROVIDER !== 'local') {
      backend.current?.signIn();
    } else {
      dispatch(openLoginModal());
    }
  }, [dispatch])

  return <Page id={'landing'} showSubHeader={true}>
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        {!authenticated && <Button size="xl" variant="light" compact onClick={(signIn)}>
          <FormattedMessage defaultMessage={'Sign in to get started'} />
        </Button>}
        {authenticated && <FormattedMessage defaultMessage={'Welcome Bastian Balthazar Bux! The boundaries of Fantasia are the boundaries of your imagination.'} />}
      </div>
    </Container>
  </Page>;
}
