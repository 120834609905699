import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
// import { v4 as uuidv4 } from 'uuid';
import { IntlShape, useIntl } from "react-intl";
import { Backend, User } from "./backend";
// import { GameManager } from "./";
import { useAppDispatch } from "../store";
// import { openOpenAIApiKeyPanel } from "../store/settings-ui";
// import { Message, Parameters, TranscriptionParameters, Story } from "./game/types";
// import { useGame, UseGameResult } from "./game/use-game";
// import { TTSContextProvider } from "./tts/use-tts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { isProxySupported } from "./game/openai";
// import { resetAudioContext } from "./tts/audio-file-player";
// import { useMicVAD, utils } from "@ricky0123/vad-react"
// import * as lamejs from 'lamejs';
// import { screenWakeLock, screenWakeUnlock } from './utils';
// import { removeFromAutoplayed } from "../components/tts-button";
// import { setMessage } from "../store/message";

// Audio constant settings
const promptVolume = 0.5;   // Volume for the prompt sound
const themeVolume = 0.5;    // Volume for the theme Music
const fadeoutSeconds = 5;   // Fade-out duration in seconds for the theme Music

export interface Context {
    authenticated: boolean;
    sessionExpired: boolean;
    user: User | null;
    intl: IntlShape;
    isHome: boolean;
    isStoryEditor: boolean;
    isUserEditor: boolean;
    isWaitlistEditor: boolean;
    appBaseURL: string;
    editor: string;
    setEditor: (editor: string) => void;
    chapterTreeMode: boolean;
    setChapterTreeMode: (mode: boolean) => void;
}

const AppContext = React.createContext<Context>({} as any);

const backend = new Backend();

let intl: IntlShape;

export function useCreateAppContext(): Context {
    // const dispatch = useAppDispatch();

    // const navigate = useNavigate();

    intl = useIntl();
    
    const { pathname } = useLocation();
    const isHome = pathname === '/';
    const isStoryEditor = pathname.startsWith('/story');
    const isUserEditor = pathname.startsWith('/user');
    const isWaitlistEditor = pathname.startsWith('/waitlist');

    // set appBaseURL assuming its port is one lower than the admin port
    const baseURL = window.location.origin;
    const adminPort = Number(window.location.port);
    const appPort = adminPort - 1;
    let appBaseURL = baseURL.replace(adminPort.toString(), appPort.toString());
    
    // if the baseURL contains "bbb" then we are in the production environment
    // then the appBaseURL should be the same as the baseURL but replacing "bbb" with "falkor"
    if (baseURL.includes("bbb")) {
        appBaseURL = baseURL.replace("bbb", "falkor");
    }

    const [authenticated, setAuthenticated] = useState(backend?.isAuthenticated || false);
    const [wasAuthenticated, setWasAuthenticated] = useState(backend?.isAuthenticated || false);

    const updateAuth = useCallback((authenticated: boolean) => {
        setAuthenticated(authenticated);
        if (authenticated && backend.user) {
            // gameManager.login(backend.user.email || backend.user.id);
        }
        if (authenticated) {
            setWasAuthenticated(true);
            localStorage.setItem('registered', 'true');
        }
    }, []);

    useEffect(() => {
        updateAuth(backend?.isAuthenticated || false);
        backend?.on('authenticated', updateAuth);
        return () => {
            backend?.off('authenticated', updateAuth)
        };
    }, [updateAuth]);

    const [editor, setEditor] = useState('story');
    const [chapterTreeMode, setChapterTreeMode] = useState(false);

    // if the current url starts with /:edtior, set the editor to :editor
    useEffect(() => {
        if (window.location.pathname.startsWith('/story')) {
            setEditor('story');
            setChapterTreeMode(false);
        } else if (window.location.pathname.startsWith('/user')) {
            setEditor('user');
        } else if (window.location.pathname.startsWith('/waitlist')) {
            setEditor('waitlist');
        }
    }, [setEditor, setChapterTreeMode]);

    const context = useMemo<Context>(() => ({
        authenticated,
        sessionExpired: !authenticated && wasAuthenticated,
        user: backend.user,
        intl,
        isHome,
        isStoryEditor,
        isUserEditor,
        isWaitlistEditor,
        appBaseURL,
        editor,
        setEditor,
        chapterTreeMode,
        setChapterTreeMode,
    }), [authenticated, wasAuthenticated, isHome, isStoryEditor, isUserEditor, isWaitlistEditor, intl, appBaseURL, 
        editor, setEditor, chapterTreeMode, setChapterTreeMode]);

    return context;
}

export function useAppContext() {
    return React.useContext(AppContext);
}

export function AppContextProvider(props: { children: React.ReactNode }) {
    const context = useCreateAppContext();
    return <AppContext.Provider value={context}>
            {props.children}
    </AppContext.Provider>;
}