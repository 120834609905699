import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { AiOutlineLoading } from "react-icons/ai";
import TreeView from "react-accessible-treeview";
import cx from "classnames";
import { useAppContext } from '../../core/context';
import { backend } from '../../core/backend';
import { Chapter, Choice, Story } from '../../core/types';
import { Alert, Button, JsonInput, Loader, Space, Col, TextInput, Textarea, Group, Grid, Text, List, Menu, Center, ActionIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
    @keyframes spinner {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
    
    .loading-icon {
        animation: spinner 1.5s linear infinite;
        margin-left: 5px;
    }
    
    .visually-hidden {
        position: absolute;
        clip-path: circle(0);
        border: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
        white-space: nowrap;
    }

    .checkbox {
        font-size: 16px;
        user-select: none;
        min-height: 320px;
        padding: 5px;
        box-sizing: content-box;
    }
    
    .checkbox .tree,
    .checkbox .tree-node,
    .checkbox .tree-node-group {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .checkbox .tree-branch-wrapper,
    .checkbox .tree-node__leaf {
        outline: none;
    }
    
    .checkbox .tree-node {
        cursor: pointer;
    }
    
    .checkbox .tree-node .name:hover {
        // background: rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.1);
    }
    
    .checkbox .tree-node--focused .name {
        // background: rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.2);
    }
    
    .checkbox .tree .tree-node--selected {
        background: rgba(48, 107, 176);
    }
    
    .checkbox .tree-node {
        display: inline-block;
    }
    
    .checkbox .checkbox-icon {
        margin: 0 5px;
        vertical-align: middle;
    }
    
    .checkbox button {
        border: none;
        background: transparent;
        cursor: pointer;
    }
    
    .checkbox .arrow {
        margin-left: 5px;
        vertical-align: middle;
    }
    
    .checkbox .arrow--open {
        transform: rotate(90deg);
    }
  
    .probability {
        margin-right: 5px;
        font-size: 0.6em;
        border: 1px solid #ccc; /* Adjust the color as needed */
        border-radius: 20%; /* This creates the round shape */
        padding: 1px; /* Adjust padding to control the size */
        display: inline-block; /* Ensures the padding and border are applied correctly */
    }
`;

const ArrowIcon = ({ isOpen }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen }
    );
    return <IoMdArrowDropright className={classes} />;
};

const CheckBoxIcon = ({ variant, ...rest }) => {
    switch (variant) {
        case "all":
            return <FaCheckSquare {...rest} />;
        case "none":
            return <FaSquare {...rest} />;
        case "some":
            return <FaMinusSquare {...rest} />;
        default:
            return null;
    }
};

interface ChapterFormProps {
    selectedChapter: Chapter | undefined;
    onSubmit: (chapter: Chapter) => void;
}

const INITIAL_USER_CHOICE = '[begin]';
const GAME_OVER_CHOICE = '[end]';

function isRootChapter(chapter: Chapter) {
    return chapter.choices && chapter.choices.some(isRootChoice);
}

function isRootChoice(choice: Choice) {
    return choice.text === INITIAL_USER_CHOICE;
}

function isRootElement(element: any) {
    return element.parent === "viewroot";
}

function isGameOverChoice(choice: Choice) {
    return choice.text === GAME_OVER_CHOICE;
}

export function ChapterEditForm({ selectedChapter, onSubmit }: ChapterFormProps) {
    const form = useForm<Chapter>({
        initialValues: selectedChapter,
    });

    useEffect(() => {
        if (selectedChapter) {
            form.setValues(selectedChapter);
            form.resetDirty(selectedChapter);
        }
    }, [selectedChapter]);

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(form.values);
    };

    const choiceList = useMemo(() => {
        let choices = form.values?.choices;

        if (!choices) {
            choices = [];
        }

        return choices.map((choice, index) => {
            if (isRootChoice(choice)) {
                return <Text>{choice.text}</Text>;
            }

            return <Draggable key={index} index={index} draggableId={index.toString()}>
                {(provided) => (
                    <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
                        <Center {...provided.dragHandleProps}>
                            <i className="fas fa-grip-vertical"></i>
                        </Center>
                        <TextInput placeholder="Choice text" {...form.getInputProps(`choices.${index}.text`)} />
                        <ActionIcon onClick={() => form.removeListItem('choices', index)}>
                            <i className="fa fa-times" />
                        </ActionIcon>
                    </Group>
                )}
            </Draggable>
        });
    }, [form.values?.choices]);

    if (!selectedChapter) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type="hidden" name="_id" value={form.values._id} />
            {/* <input type="hidden" name="storyName" value={form.values.storyName} />
            <input type="hidden" name="storyVersion" value={form.values.storyVersion} /> */}
            {/* <Group position="left">
                <TextInput {...form.getInputProps('_id')}
                    label="Chapter ID"
                    required disabled
                /> 
            </Group> */}
            <Text fz="xs">Chapter ID: {selectedChapter._id}</Text>

            <Group position="left">
                <Textarea w="100%" {...form.getInputProps('content')}
                    label="Content"
                    required
                    autosize
                    maxRows={30}
                />
                <TextInput {...form.getInputProps('generator')}
                    label="Generator"
                    required
                />
                {/* Choices List */}

            </Group>

            {!isRootChapter(form.values) && <>
                <Group position="left" mt="lg">
                    <h3>Choices</h3>
                    <Button onClick={() => form.insertListItem('choices', { name: '', value: '' })}>
                        <i className="fa fa-plus" />
                    </Button>

                    <DragDropContext
                        onDragEnd={({ destination, source }) =>
                            form.reorderListItem('choices', { from: source.index, to: destination.index })
                        }
                    >
                        <Droppable droppableId="stats-dnd-list" direction="vertical">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {choiceList}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Group>
            </>}

            <Group position="left" mt="md">
                <Button type="submit">Update Chapter</Button>
            </Group>
        </form>
    );
}
interface ChapterTreeProps {
    story: Story;
}

export default function ChapterTree({ story }: ChapterTreeProps) {
    const context = useAppContext();
    const loadedAlertElement = useRef<HTMLDivElement>(null);
    const [nodesAlreadyLoaded, setNodesAlreadyLoaded] = useState<any[]>([]);
    const [subtree, setSubtree] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [okMessage, setOkMessage] = useState<string | undefined>(undefined);
    const [showGenerateButton, setShowGenerateButton] = useState<boolean>(false);

    const [selectedChapterId, setSelectedChapterId] = useState<string | undefined>(undefined);
    const [selectedChapter, setSelectedChapter] = useState<Chapter | undefined>(undefined);

    const fetchSubtree = useCallback(async () => {
        // setOkMessage(undefined);
        setErrorMessage(undefined);
        setShowGenerateButton(false);

        if (!story) {
            return;
        }

        try {
            const result = await backend.current?.getChapterTree(story);
            setSubtree(result);
        } catch (error: any) {
            setSubtree([]);
            if (error.message === 'Not Found') {
                setShowGenerateButton(true);
            } else {
                setErrorMessage(error.message);
            }
        }
    }, [story]);

    useEffect(() => {
        if (story) {
            fetchSubtree();
        }
    }, [story]);

    useEffect(() => {
        if (selectedChapterId) {
            backend.current?.getChapter(selectedChapterId)
                .then((chapter) => {
                    setSelectedChapter(chapter);
                }).catch((error) => {
                    setSelectedChapter(undefined)
                    setErrorMessage(error.message);
                });
        }
    }, [selectedChapterId, story]);

    const updateTreeData = (list, id, descendants) => {
        // get descendants ids for comparison
        const descendantIds = descendants.map((d) => d.id);

        // subtree should nodes in the list that are not in descendantIds
        const subtree = list.filter((node) => !descendantIds.includes(node.id));

        // add descendants to the subtree overwriting existing ones which are just shells
        return subtree.concat(descendants);
    };

    const onLoadData = ({ element }) => {
        return new Promise((resolve) => {
            if (element.children.length > 0) {
                resolve(void 0);
                return;
            }

            // for choices, we want to load the children of the parent
            const fromChapterId = element.metadata?.isChoice ? element.parent : element.id;

            backend.current?.getChapterTree(story, fromChapterId)
                .then(childChapters => {
                    setSubtree((value) => {
                        return updateTreeData(value, fromChapterId, childChapters);
                    });
                });
            resolve(void 0);
        });
    };

    const wrappedOnLoadData = async (props) => {
        const nodeHasNoChildData = props.element.children.length === 0;
        const nodeHasAlreadyBeenLoaded = nodesAlreadyLoaded.find(
            (e) => e.id === props.element.id
        );

        await onLoadData(props);

        if (nodeHasNoChildData && !nodeHasAlreadyBeenLoaded) {
            const el = loadedAlertElement.current; // Add type assertion
            setNodesAlreadyLoaded([...nodesAlreadyLoaded, props.element]);
            el && (el.innerHTML = `${props.element.name} loaded`);

            // Clearing aria-live region so loaded node alerts no longer appear in DOM
            setTimeout(() => {
                el && (el.innerHTML = "");
            }, 5000);
        }
    };

    const generateChapterTree = useCallback(async (story: Story) => {
        try {
            const result = await backend.current?.generateRootChapter(story.name, story.version);
            setOkMessage('Root chapter generated');
            setErrorMessage(undefined);
            fetchSubtree();
            setShowGenerateButton(false);
        } catch (error: any) {
            setSubtree([]);
            setErrorMessage(error.message);
        }
    }, [backend.current]);

    const onUpdateChapter = useCallback(async (chapter: Chapter) => {
        try {
            // update chapter in the backend
            await backend.current?.updateChapter(chapter);
            // reload the updated element
            await wrappedOnLoadData({ element: { id: chapter._id, children: [] } });
            // give feedback to the user
            setOkMessage('Chapter updated');
            setErrorMessage(undefined);
        } catch (error: any) {
            setOkMessage(undefined);
            setErrorMessage(error.message);
        }
    }, [backend.current]);

    const onExpand = useCallback(({ element, isExpanded, isSelected, isHalfSelected, isDisabled, treeState }) => {
        const chapterId = element.id;
        if (chapterId !== selectedChapterId) {
            if (element.metadata?.isChapter) {
                setSelectedChapterId(chapterId);
            } else if (element.metadata?.isChoice) {
                setSelectedChapterId(element.parent);
            }
        }

        return true;
    }, [selectedChapterId]);

    const generateConsquences = useCallback(async (element: any) => {
        try {
            const parentChapterId = element.parent;
            const choiceText = element.name;
            // set element to expanded and childless so that the node will appear loading
            element.children = [];
            element.isExpanded = true;
            await wrappedOnLoadData({ element });
            // updateTreeData(subtree, parentChapterId, []);
            // fetchSubtree();
            const result = await backend.current?.generateConsequences(parentChapterId, choiceText);
            setOkMessage('Consquences generated');
            setErrorMessage(undefined);
            // reload the updated element
            await onLoadData({ element });
            // give feedback to the user
            setShowGenerateButton(false);
        } catch (error: any) {
            setSubtree([]);
            setErrorMessage(error.message);
        }
    }, [backend.current]);

    const deleteChapter = useCallback(async (element: any) => {
        try {
            if (isRootElement(element)) {
                setErrorMessage("Root chapter cannot be deleted. Please delete the story or create a new version instead.");
                return;
            }

            const chapterId = element.id;
            await backend.current?.deleteChapter(chapterId);
            setOkMessage('Chapter deleted');
            setErrorMessage(undefined);
            fetchSubtree();
        } catch (error: any) {
            setSubtree([]);
            setErrorMessage(error.message);
        }
    }, [backend.current]);

    const getChapterProbability = useCallback((element: any) => {
        // find the element's parent element
        const parentId = element.parent;
        if (parentId === "viewroot") {
            return null;
        }

        const parentChoice = subtree.find((e) => e.id === parentId);
        if (!parentChoice) {
            return null;
        }

        // get the probMap from the parent choice
        const probMapArray = parentChoice.metadata?.probMap;
        if (!probMapArray) {
            return null;
        }

        // probMap is a map of child chapter ids to probabilities created like this on the server:
        // const probMap = choice.consequences?.map(consequence => ({ [consequence.childId]: consequence.prob })) || [];
        const probMap = new Map(probMapArray);

        // get the prob from the probMap
        const prob = probMap.get(element.id) || 0;

        // sum all probabilities for the parent choice
        const sum = probMapArray.reduce((acc, [_, prob]) => acc + prob, 0);

        // the probability as a percentage rounded to the nearest int
        const probPercentage = prob ? Math.round(prob / sum * 100) : null;

        // return the probability as a percentage
        return probPercentage;

    }, [subtree]);

        
    if (!subtree || subtree.length === 0) {
        if (showGenerateButton) {
            return <>
                <Alert color="blue">The chapter tree for this story has yet to be created.
                    <Space h="md" />
                    <Button onClick={() => generateChapterTree(story)}>Generate</Button>
                </Alert>
            </>;
        } else if (errorMessage) {
            return <Alert color="red" title="Error" withCloseButton onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>;
        } else {
            return <Loader />;
        }
    }

    // const prettyPrintSubtree = JSON.stringify(subtree, null, 2);

    return (
        <Container>
            <Grid>
                <Grid.Col span={6}>
                    {okMessage && <Alert color="blue" title="OK" withCloseButton onClose={() => setOkMessage(undefined)}>{okMessage}</Alert>}
                    {errorMessage && <Alert color="red" title="Error" withCloseButton onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>}
                    {/* <JsonInput value={prettyPrintSubtree} minRows={10} /> */}
                    <div
                        className="visually-hidden"
                        ref={loadedAlertElement}
                        role="alert"
                        aria-live="polite"
                    ></div>
                    <div className="checkbox" style={{ overflow: 'auto', maxHeight: '100vh' }}>
                        {subtree && <TreeView
                            data={subtree}
                            aria-label="Chapter tree"
                            onLoadData={wrappedOnLoadData}
                            // onExpand={onExpand}
                            multiSelect
                            propagateSelect
                            togglableSelect
                            propagateSelectUpwards
                            // selectedIds={selectedChapterId ? [selectedChapterId] : []}
                            // clickAction='FOCUS'
                            nodeRenderer={({
                                element,
                                isBranch,
                                isExpanded,
                                isSelected,
                                isHalfSelected,
                                getNodeProps,
                                level,
                                handleSelect,
                                handleExpand,
                            }) => {
                                const branchNode = (isExpanded, element) => {
                                    return isExpanded && element.children.length === 0 ? (
                                        <>
                                            <span
                                                role="alert"
                                                aria-live="assertive"
                                                className="visually-hidden"
                                            >
                                                loading {element.name}
                                            </span>
                                            <AiOutlineLoading
                                                aria-hidden={true}
                                                className="loading-icon"
                                            />
                                        </>
                                    ) : (
                                        <ArrowIcon isOpen={isExpanded} />
                                    );
                                };
                                return (
                                    <div
                                        {...getNodeProps({ onClick: handleExpand })}
                                        style={{ marginLeft: 40 * (level - 1), whiteSpace: 'nowrap', alignItems: 'center' }}
                                    >
                                        {isBranch && branchNode(isExpanded, element)}
                                        {/* {element.metadata?.isChapter &&
                                            <CheckBoxIcon
                                                className="checkbox-icon"
                                                onClick={(e) => {
                                                    handleSelect(e);
                                                    e.stopPropagation();
                                                }}
                                                variant={
                                                    isHalfSelected ? "some" : isSelected ? "all" : "none"
                                                }
                                            />
                                        } */}
                                        <Menu shadow="md" width={300}>
                                            <Menu.Target>
                                                <span className="name" style={{ whiteSpace: 'nowrap' }}>
                                                    {element.metadata?.isChapter && <>
                                                        {!isRootElement(element) && <span className="probability">{getChapterProbability(element)}</span>}
                                                        <i className="fas fa-book" />
                                                    </>}
                                                    {element.metadata?.isConsequence && <>
                                                        {element.metadata?.prob && <span className="probability">{element.metadata.prob}</span>}
                                                        <i className="fas fa-book" />
                                                    </>}
                                                    {element.metadata?.isChoice && <i className="fas fa-hand-point-right" />}
                                                    {isRootElement(element) ? "Root " : ""} {element.name}
                                                </span>
                                            </Menu.Target>

                                            {element.metadata?.isChapter && <Menu.Dropdown>
                                                <Menu.Label>{isRootElement(element) ? "Root " : "Chapter"} {element.id}</Menu.Label>
                                                <Menu.Item
                                                    icon={<i className="fas fa-edit" />}
                                                    onClick={() => onExpand({ element, isExpanded, isSelected, isHalfSelected, isDisabled: false, treeState: {} })}
                                                >Edit</Menu.Item>
                                                <Menu.Divider />

                                                <Menu.Label>Danger zone</Menu.Label>
                                                <Menu.Item 
                                                    color="red" 
                                                    icon={<i className="fas fa-trash" />}
                                                    onClick={() => deleteChapter(element)}>
                                                    Delete chapter</Menu.Item>
                                            </Menu.Dropdown>}

                                            {element.metadata?.isChoice && <Menu.Dropdown>
                                                <Menu.Label>Choice {element.id}</Menu.Label>
                                                <Menu.Item
                                                    icon={<i className="fas fa-plus" />}
                                                    onClick={() => generateConsquences(element)}>
                                                    Generate consequences</Menu.Item>
{/* 
                                                <Menu.Divider />
                                                <Menu.Label>Danger zone</Menu.Label>
                                                <Menu.Item 
                                                    color="red" 
                                                    icon={<i className="fas fa-trash" />}
                                                    onClick={() => deleteChoice(element)}>
                                                    Delete choice</Menu.Item> */}
                                            </Menu.Dropdown>}
                                        </Menu>
                                    </div>
                                );
                            }}
                        />}
                    </div>
                </Grid.Col>
                <Grid.Col span={6}>
                    <ChapterEditForm selectedChapter={selectedChapter} onSubmit={onUpdateChapter} />
                </Grid.Col>
            </Grid>
        </Container>
    );
}
