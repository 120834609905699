import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeModals, selectModal } from '../../store/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';
import { Button, Modal, TextInput } from '@mantine/core';
import { backend } from '../../core/backend';
import { useNavigate } from 'react-router-dom';
import { Story } from '../../core/types';
import { useAppContext } from '../../core/context';

const Container = styled.form`
    * {
        font-family: "Work Sans", sans-serif;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .mantine-TextInput-root, .mantine-PasswordInput-root {
        margin-top: 1rem;
    }
    
    .mantine-TextInput-root + .mantine-Button-root,
    .mantine-PasswordInput-root + .mantine-Button-root {
        margin-top: 1.618rem;
    }

    .mantine-Button-root {
        margin-top: 1rem;
    }

    label {
        margin-bottom: 0.25rem;
    }
`;

export function NewStoryModal(props: any) {
    const modal = useAppSelector(selectModal);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const context = useAppContext();

    const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);
    // const onCreateStoryClick = useCallback(() => dispatch(openNewStoryModal()), [dispatch]);

    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        const form = event.currentTarget;
        const formData = new FormData(form);
        
        const name = formData.get('name') as string;
        const version = formData.get('version') as string;
        const author = formData.get('author') as string;

        const newStory : Story = {
            name: name,
            version: version,
            author: author,
            censored: true, // we censore by default
            title: name,
            category: "",
            prompt: "",

        }

        const result = await backend.current?.updateStory(newStory);

        if (result) {
            // After form submission, redirect to another page:
            navigate('/story/' + newStory.name + '/' + newStory.version + '/');
        }

        dispatch(closeModals())

    }, [navigate, dispatch]);

    return <Modal opened={modal === 'new-story'} onClose={onClose} withCloseButton={false}>
        <Container method="post" onSubmit={handleSubmit}>
            <h2>
                <FormattedMessage defaultMessage={"Create a new story"} />
            </h2>
            <TextInput
                label={intl.formatMessage({ defaultMessage: "Name" })}
                name="name"
                placeholder={intl.formatMessage({ defaultMessage: "Unique name for this story" })}
                type="text"
                required />
            <TextInput
                label={intl.formatMessage({ defaultMessage: "Version" })}
                name="version"
                defaultValue={0.1}
                type="text"
                required />
            <TextInput
                label={intl.formatMessage({ defaultMessage: "Author" })}
                name="author"
                defaultValue={context.user?.name || intl.formatMessage({ defaultMessage: "Anonymous" })}
                type="text"
                required />
            <input type="hidden" name="censored" value="true" />
            <Button fullWidth type="submit">
                <FormattedMessage defaultMessage={"Create"} />
            </Button>
            {/* <Button fullWidth variant="subtle" onClick={onCreateStoryClick}>
                <FormattedMessage defaultMessage={"Or create an account"} description="Label for a button on the Sign In page that lets the user create an account instead" />
            </Button> */}
        </Container>
    </Modal>
}