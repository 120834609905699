import EventEmitter from 'events';
import * as Y from 'yjs';
import { encode, decode } from '@msgpack/msgpack';
// import { MessageTree } from './game/message-tree';
// import { Game } from './game/types';
import { AsyncLoop } from "./utils/async-loop";
// import { GameManager } from '.';
import { getRateLimitResetTimeFromResponse } from './utils';
// import { importGame } from './game/game-persistance';
import * as encoding from 'lib0/encoding';
import * as decoding from 'lib0/decoding';
import * as syncProtocol from 'y-protocols/sync';
import { Auth0User, Chapter, Story } from './types';
import { maxHeaderSize } from 'http';

const endpoint = '/bbbapi';

export let backend: {
    current?: Backend | null
} = {};

export interface User {
    id: string;
    email?: string;
    name?: string;
    avatar?: string;
    services?: string[];
}

export class Backend extends EventEmitter {
    public user: User | null = null;
    public services: string[] = [];
    private checkedSession = false;

    private sessionInterval = new AsyncLoop(() => this.getSession(), 1000 * 30);
    // private syncInterval = new AsyncLoop(() => this.sync(), 1000 * 5);

    // private pendingYUpdate: Uint8Array | null = null;
    private lastFullSyncAt = 0;
    // private legacySync = false;
    private rateLimitedUntil = 0;

    public constructor() {
        super();

        if ((window as any).AUTH_PROVIDER) {
            backend.current = this;

            this.sessionInterval.start();
            // this.syncInterval.start();
        }
    }

    public isSynced() {
        return (this.checkedSession && !this.isAuthenticated) || this.lastFullSyncAt > 0;
    }

    public async getSession() {
        if (Date.now() < this.rateLimitedUntil) {
            console.log(`Waiting another ${this.rateLimitedUntil - Date.now()}ms to check session due to rate limiting.`);
            return;
        }

        const wasAuthenticated = this.isAuthenticated;
        const session = await this.get(endpoint + '/session');

        if (session?.authProvider) {
            (window as any).AUTH_PROVIDER = session.authProvider;
        }

        if (session?.authenticated) {
            this.user = {
                id: session.userID,
                email: session.email,
                name: session.name,
                avatar: session.picture,
                services: session.services,
            };
            this.services = session.services || [];
        } else {
            this.user = null;
            this.services = session?.services || [];
        }

        this.checkedSession = true;

        if (wasAuthenticated !== this.isAuthenticated) {
            this.emit('authenticated', this.isAuthenticated);
            this.lastFullSyncAt = 0;
        }
    }

    // public async sync() {
    //     if (!this.isAuthenticated) {
    //         return;
    //     }

    //     if (Date.now() < this.rateLimitedUntil) {
    //         console.log(`Waiting another ${this.rateLimitedUntil - Date.now()}ms before syncing due to rate limiting.`);
    //         return;
    //     }

    //     const sinceLastFullSync = Date.now() - this.lastFullSyncAt;

    //     const pendingYUpdate = this.pendingYUpdate;
    //     if (pendingYUpdate && pendingYUpdate.length > 4) {
    //         this.pendingYUpdate = null;

    //         const encoder = encoding.createEncoder();
    //         syncProtocol.writeUpdate(encoder, pendingYUpdate);

    //         const response = await fetch(endpoint + '/y-sync', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/octet-stream'
    //             },
    //             body: encoding.toUint8Array(encoder),
    //         });

    //         if (response.status === 429) {
    //             this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
    //         }
    //     } else if (sinceLastFullSync > 1000 * 60 * 1) {
    //         this.lastFullSyncAt = Date.now();

    //         const encoder = encoding.createEncoder();
    //         syncProtocol.writeSyncStep1(encoder, this.context.doc.root);

    //         const queue: Uint8Array[] = [
    //             encoding.toUint8Array(encoder),
    //         ];

    //         for (let i = 0; i < 4; i++) {
    //             if (!queue.length) {
    //                 break;
    //             }

    //             const buffer = queue.shift()!;

    //             const response = await fetch(endpoint + '/y-sync', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/octet-stream'
    //                 },
    //                 body: buffer,
    //             });

    //             if (!response.ok) {
    //                 this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
    //                 throw new Error(response.statusText);
    //             }

    //             const responseBuffer = await response.arrayBuffer();
    //             const responseChunks = decode(responseBuffer) as Uint8Array[];

    //             for (const chunk of responseChunks) {
    //                 if (!chunk.byteLength) {
    //                     continue;
    //                 }

    //                 const encoder = encoding.createEncoder();
    //                 const decoder = decoding.createDecoder(chunk);

    //                 const messageType = decoding.readVarUint(decoder);
    //                 decoder.pos = 0;

    //                 syncProtocol.readSyncMessage(decoder, encoder, this.context.doc.root, 'sync');

    //                 if (encoding.length(encoder)) {
    //                     queue.push(encoding.toUint8Array(encoder));
    //                 }
    //             }
    //         }

    //         this.context.emit('update');
    //     }

    //     if (!this.legacySync) {
    //         this.legacySync = true;

    //         const games = await this.get(endpoint + '/legacy-sync');

    //         this.context.doc.transact(() => {
    //             for (const game of games) {
    //                 try {
    //                     importGame(this.context.doc, game);
    //                 } catch (e) {
    //                     console.error(e);
    //                 }
    //             }
    //         });
    //     }
    // }

    // public receiveYUpdate(update: Uint8Array) {
    //     if (!this.pendingYUpdate) {
    //         this.pendingYUpdate = update;
    //     } else {
    //         this.pendingYUpdate = Y.mergeUpdates([this.pendingYUpdate, update]);
    //     }
    // }

    async signIn() {
        window.location.href = endpoint + '/login';
    }

    get isAuthenticated() {
        return this.user !== null;
    }

    async logout() {
        window.location.href = endpoint + '/logout';
    }

    // async shareGame(game: Game): Promise<string | null> {
    //     try {
    //         const { id } = await this.post(endpoint + '/share', {
    //             ...game,
    //             messages: game.messages.serialize(),
    //         });
    //         if (typeof id === 'string') {
    //             return id;
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    //     return null;
    // }

    // async getSharedGame(id: string): Promise<Game | null> {
    //     const format = process.env.REACT_APP_SHARE_URL || (endpoint + '/share/:id');
    //     const url = format.replace(':id', id);
    //     try {
    //         const game = await this.get(url);
    //         if (game?.messages?.length) {
    //             game.messages = new MessageTree(game.messages);
    //             return game;
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    //     return null;
    // }

    // async deleteGame(id: string) {
    //     if (!this.isAuthenticated) {
    //         return;
    //     }

    //     return this.post(endpoint + '/delete', { id });
    // }

    async getUsers() {
        return await this.get(endpoint + '/auth0/v2/users');
    }

    async getUser(id: string, field?: string) {
        const fieldFilter = field ? `/${field}/` : '';
        return await this.get(endpoint + `/auth0/v2/users/${id}${fieldFilter}`);
    }

    async getUserSubscriptions(id: string) : Promise<string> {
        try {
            const result = await this.get(endpoint + `/user/subscriptions/${id}`);
            if (result) {
                return JSON.stringify(result);
            } 
        } catch (error: any) {
        }

        return '';
    }

    async setUserSubscriptions(id: string, subscriptions: string) {
        return await this.post(endpoint + `/user/subscriptions/${id}`, subscriptions, 'PATCH');
    }

    async updateUser(user: Auth0User) {
        return await this.post(endpoint + `/auth0/v2/users/${user.user_id}`, user, 'PATCH');
    }

    async deleteUser(id: string) {
        return await this.post(endpoint + `/auth0/v2/users/${id}`, {}, 'DELETE');
    }

    async inviteUsers(emails: string[]) {
        return await this.post(endpoint + '/invite', { emails });
    }

    /**
     * Get the waitlist from the server or a specific user if email is provided
     * @param email 
     * @returns list of users on the waitlist or a specific user if email is provided
     */
    async getWaitlist(email?: string) {
        const waitlist = await this.post(endpoint + '/waitlist', { includeInvited: true });
        if (email) {
            return waitlist.find((user: Auth0User) => user.email === email);
        } else {
            return waitlist;
        }
    }

    async inviteWaiters(emails: string[]) {
        return await this.post(endpoint + '/waitlist/invite', { emails });
    }

    async deleteWaiters(emails: string[]) {
        return await this.post(endpoint + '/waitlist/delete', { emails });
    }

    async getStories() {
        return await this.get(endpoint + '/story/list');
    }

    // get a version=>story map of stories with this name (and version if specified)
    async getStory(name: string, version: string | undefined) {
        // build url
        let getStoryURL = endpoint + `/story/edit/${name}/`;
        if (version) {
            getStoryURL += version + '/';
        }

        // get stories from server
        const stories = await this.get(getStoryURL);

        // create a map from the array of stories
        const storyMap = new Map<string, Story>(stories.map((story: Story) => [story.version, story]));

        return storyMap;
    }

    async updateStory(story: Story) {
        return await this.post(endpoint + '/story/update', story);
    }

    async censorStory(name: string, version: string, censor: boolean) {
        const censorPart = censor ? 'censor' : 'uncensor';
        return await this.get(endpoint + `/story/${censorPart}/${name}/${version}/`);
    }

    async purgeStory(id: string) {
        return await this.get(endpoint + `/story/purge/${id}/`);
    }

    async purgeStoriesByName(name: string) {
        // get all stories
        const stories = await this.getStories();

        // get all stories with this name
        const storiesWithName = stories.filter((story: Story) => story.name === name);

        if (storiesWithName.length === 0) {
            return;
        }

        // purge all stories with this name
        for (const story of storiesWithName) {
            await this.purgeStory(story._id);
        }
    }

    async uploadMedia(mediaFile: File) {
        const formData = new FormData();
        formData.append('file', mediaFile);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
        }
    
        const response = await fetch(endpoint + '/media/upload', {
            method: "POST",
            headers: headers,
            body: formData,
        });
        
        if (response.status === 429) {
            this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
        }

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const resJson = await response.json();

        if (resJson?.mediaURL) {
            return resJson.mediaURL;
        } else {
            throw new Error("Failed to upload media");
        }
    }

    async getChapterTree(story: Story, fromChapterId?: string) {
        return await this.post(endpoint + '/story/chaptertree',
            { 
                storyName: story.name, 
                storyVersion: story.version,
                fromChapterId: fromChapterId,
                maxDepth: 0,
            },
        );
    }

    async getChapter(id: string) {
        const url = endpoint  + `/story/chapter/${id}`;
        return this.get(url);
    }

    async updateChapter(chapter: Chapter) {
        const url = endpoint + '/story/chapter/update';
        return this.post(url, chapter);
    }

    async deleteChapter(id: string) {
        const url = endpoint + `/story/chapter/delete/${id}`;
        return this.post(url, {}, 'DELETE');
    }

    async generateRootChapter(name: string, version: string) {
        const url = endpoint + `/story/generate/root/${name}/${version}`;
        return this.get(url);
    }

    async generateConsequences(parentId: string, choiceText: string) {
        const url = endpoint + '/story/generate/consequence';
        return this.post(url, { parentId, choiceText });
    }

    async get(url: string) {
        const response = await fetch(url);
        if (response.status === 429) {
            this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
        }
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }

    async post(url: string, data: any, method?: string) {
        const response = await fetch(url, {
            method: method ? method : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (response.status === 429) {
            this.rateLimitedUntil = getRateLimitResetTimeFromResponse(response);
        }
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }
}