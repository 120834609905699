import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { createBrowserRouter, BrowserRouter as Router, RouterProvider, Routes, Route } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { AppContextProvider } from './core/context';
import store, { persistor } from './store';

import LandingPage from './components/pages/landing';

import "./index.css";
import StoryEditor from './components/pages/story-editor';
import UserAdmin from './components/pages/user-admin';
import WaitlistAdmin from './components/pages/waitlist-admin';

// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <AppContextProvider>
//                 <LandingPage landing={true} />
//             </AppContextProvider>
//     },
//     {
//         path: "/story/:name",
//         element: <AppContextProvider>
//             <StoryEditor />
//         </AppContextProvider>,
//     },
// ]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

async function loadLocaleData(locale: string) {
    const response = await fetch(`/lang/${locale}.json`);
    if (!response.ok) {
        throw new Error("Failed to load locale data");
    }
    const messages: any = await response.json();
    for (const key of Object.keys(messages)) {
        if (typeof messages[key] !== 'string') {
            messages[key] = messages[key].defaultMessage;
        }
    }
    return messages;
}

async function bootstrapApplication() {
    const locale = navigator.language;

    let messages: any;
    try {
        messages = await loadLocaleData(locale.toLocaleLowerCase());
    } catch (e) {
        console.warn("No locale data for", locale);
    }

    root.render(
        // <React.StrictMode>
            <IntlProvider locale={navigator.language} defaultLocale="en-GB" messages={messages}>
                <MantineProvider theme={{ colorScheme: "dark" }}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <ModalsProvider>
                                <Router>
                                    <Routes>
                                        <Route path="/" element={<AppContextProvider><LandingPage landing={true} /></AppContextProvider>} />
                                        <Route path="/story/" element={<AppContextProvider><StoryEditor /></AppContextProvider>} />
                                        <Route path="/story/:name/:version?" element={<AppContextProvider><StoryEditor /></AppContextProvider>} />
                                        <Route path="/user/:id?" element={<AppContextProvider><UserAdmin /></AppContextProvider>} />
                                        <Route path="/waitlist/:email?" element={<AppContextProvider><WaitlistAdmin /></AppContextProvider>} />
                                    </Routes>
                                </Router>
                            </ModalsProvider>
                        </PersistGate>
                    </Provider>
                </MantineProvider>
            </IntlProvider>
        // </React.StrictMode>
    );
}

bootstrapApplication();
