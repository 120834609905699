import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../core/context';
import { useAppDispatch } from '../../store';
import { toggleSidebar } from '../../store/sidebar';
import { ActionIcon, Avatar, Button, Col, Loader, Menu, Paper, Textarea, Tooltip, Space, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { backend } from '../../core/backend';
import { Auth0User } from '../../core/types';

const Container = styled.div`
    margin: calc(1.618rem - 1rem);
    margin-top: -0.218rem;
`;

const Empty = styled.p`
    text-align: center;
    font-size: 0.8rem;
    padding: 2rem;
`;

const Waitlist = styled.div``;

const WaitlistItemLink = styled(Link)`
    display: block;
    position: relative;
    padding: 0.4rem 1rem;
    margin: 0.218rem 0;
    line-height: 1.7;
    text-decoration: none;
    border-radius: 0.25rem;

    &:hover, &:focus, &:active {
        background: rgba(0, 0, 0, 0.1);
    }

    &.selected {
        background: #2b3d54;
    }

    strong {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.6;
        padding-right: 1rem;
        color: white;
    }

    p {
        font-size: 0.8rem;
        font-weight: 200;
        opacity: 0.8;
    }

    .mantine-ActionIcon-root {
        position: absolute;
        right: 0.0rem;
        top: 50%;
        margin-top: -22px;
        opacity: 0;
    }

    &:hover {
        .mantine-ActionIcon-root {
            opacity: 1;
        }
    }
`;

function WaitlistItem(props: { user: Auth0User, onClick: any, selected: boolean, onRefresh: any }) {
    const c = props.user;
    const context = useAppContext();
    const modals = useModals();
    const navigate = useNavigate();

    const onDelete = useCallback((e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        modals.openConfirmModal({
            title: `Are you sure you want to remove ${c.email} from the waitlist?`,
            // children: <p style={{ lineHeight: 1.7 }}>The user "{c.email}" will be removed from the wait.</p>,
            labels: {
                confirm: "Remove",
                cancel: "Cancel",
            },
            confirmProps: {
                color: 'red',
            },
            onConfirm: async () => {
                try {
                    await backend.current?.deleteWaiters([c.email]);
                    await props.onRefresh();
                    navigate('/waitlist');
                } catch (e) {
                    console.error(e);
                    modals.openConfirmModal({
                        title: "Something went wrong",
                        children: <p style={{ lineHeight: 1.7 }}>The user "{c.email}" could not be removed.</p>,
                        labels: {
                            confirm: "Try again",
                            cancel: "Cancel",
                        },
                        onConfirm: () => onDelete(),
                    });
                }
            },
        });
    }, [c.name, c.user_id]);

    // const onRename = useCallback((e: React.MouseEvent) => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     // Display a modal with a TextInput
    //     modals.openModal({
    //         title: "Rename user",
    //         children: <div>
    //             <Textarea
    //                 id="user-title"
    //                 defaultValue={c.title}
    //                 maxLength={500}
    //                 autosize
    //                 required />
    //             <Button
    //                 fullWidth
    //                 variant="light"
    //                 style={{ marginTop: '1rem' }}
    //                 onClick={() => {
    //                     const title = document.querySelector<HTMLInputElement>('#user-title')?.value?.trim();
    //                     // const ygame = context.game.doc.getYUser(c.name);
    //                     // if (yuser && title && title !== yuser?.title) {
    //                     //     yuser.title = title;
    //                     // }
    //                     modals.closeAll();
    //                 }}
    //             >
    //                 Save changes
    //             </Button>
    //         </div>,
    //     });
    // }, [c.name, c.title]);

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpen(open => !open);
    }, []);

    // format created_at as a string of the format
    // 23 minutes ago, 1 hour ago, 2 days ago, etc.
    const joinedAt = new Date(c.created_at);
    const now = new Date();
    const timeDiff = now.getTime() - joinedAt.getTime();
    let timeDiffStr = '';
    if (timeDiff < 1000 * 60) {
        timeDiffStr = 'just now';
    } else if (timeDiff < 1000 * 60 * 60) {
        const minutes = Math.floor(timeDiff / (1000 * 60));
        timeDiffStr = minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else if (timeDiff < 1000 * 60 * 60 * 24) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        timeDiffStr = hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (timeDiff < 1000 * 60 * 60 * 24 * 7) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        timeDiffStr = days === 1 ? '1 day ago' : `${days} days ago`;
    } else {
        timeDiffStr = joinedAt.toLocaleDateString();
    }

    return (
        <WaitlistItemLink to={'/waitlist/' + c.email + '/'}
            onClick={props.onClick}
            data-user-id={c.email}
            className={props.selected ? 'selected' : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip multiline
                    label={timeDiffStr || <FormattedMessage defaultMessage={"Untitled"} description="default user name for untitled users" />}>
                    <strong>{c.email}</strong>
                </Tooltip>
            </div>
            <Menu opened={menuOpen}
                closeOnClickOutside={true}
                closeOnEscape={true}
                onClose={() => setMenuOpen(false)}>
                <Menu.Target>
                    <ActionIcon size="xl" onClick={toggleMenu}>
                        <i className="fas fa-ellipsis" />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    {/* <Menu.Item onClick={onRename} icon={<i className="fa fa-edit" />}>
                        <FormattedMessage defaultMessage={"Rename this user"} />
                    </Menu.Item>
                    <Menu.Divider /> */}
                    <Menu.Item onClick={onDelete} color="red" icon={<i className="fa fa-trash" />}>
                        <FormattedMessage defaultMessage={"Delete this user"} />
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </WaitlistItemLink>
    );
}

export default function Waiters(props: any) {
    const context = useAppContext();
    const dispatch = useAppDispatch();

    const { email: selectedWaiter } = useParams<string>();
    const [waiters, setWaiters] = useState<any[]>([]);
    const [synced, setSynced] = useState<boolean>(false);

    const fetchWaitlist = useCallback(async () => {
        const result = await backend.current?.getWaitlist();

        // sort by email
        result.sort((a: any, b: any) => {
            // Provide default values for emails if they are undefined
            const emailA = a.email || '';
            const emailB = b.email || '';
            return emailA.localeCompare(emailB);
        });

        setWaiters(result);
        setSynced(true);

    }, [backend.current, waiters]);

    const onRefresh = useCallback(async () => {
        setSynced(false);
        setWaiters([]);
        await fetchWaitlist();
    }, [backend.current, waiters, selectedWaiter]);

    useEffect(
        () => {
            if (context.authenticated) {
                fetchWaitlist();
            }
        }, [context.authenticated, selectedWaiter]
    )

    const onClick = useCallback((e: React.MouseEvent) => {
        if (e.currentTarget.closest('button')) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (window.matchMedia('(max-width: 40em)').matches) {
            dispatch(toggleSidebar());
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedWaiter) {
            const el = document.querySelector(`[data-user-id="${selectedWaiter}"]`);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, [selectedWaiter]);

    return (
        <Container>
            <Button onClick={onRefresh} variant="light" color="gray"><li className="fas fa-sync" /></Button>
            {waiters.length > 0 && <Waitlist>
                {waiters.map(c => (
                    <WaitlistItem key={c.email} user={c} onClick={onClick} selected={c.email === selectedWaiter}
                        onRefresh={onRefresh} />
                ))}
            </Waitlist>}
            {waiters.length === 0 && !synced && <Empty>
                <Loader size="sm" variant="dots" />
            </Empty>}
            {waiters.length === 0 && synced && <Empty>
                <FormattedMessage defaultMessage={"No waiters found."} description="Message shown on the waitlist screen" />
            </Empty>}
        </Container>
    );
}