import styled from '@emotion/styled';
import { TextInput, Button, Group, Box, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Page } from '../page';
import { useAppContext } from '../../core/context';
import { backend } from '../../core/backend';
import { useNavigate, useParams } from 'react-router-dom';

const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: ;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
    overflow-y: auto ; /* Enable vertical scrolling */
`;

interface WaiterValue {
  email: string;
  created_at: Date;
  invited_at: Date | null;
}

export default function WaitlistAdmin() {
  // get user name from the :name parameter
  const { email } = useParams<string>();
  // let version : string | undefined = undefined;

  // if name includes a version, split it
  // if (name && name.includes('/')) {
  //   const parts = name.split('/');
  //   name = parts[0];
  //   version = parts[1];
  // }

  // the user to edit with this specific version
  const [user, setUser] = useState<WaiterValue | undefined>(undefined);
  // const [roles, setRoles] = useState<string[]>([]);
  // const [subscriptions, setSubscriptions] = useState<string>('');

  // the stories with the same name but various versions
  // const [users, setUsers] = useState<Map<string, User>>(new Map<string, User>());
  const [okMessage, setOkMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const fetchWaiters = useCallback(async () => {
    if (email) {
      // fetch specific user from backend
      try {
        const user = await backend.current?.getWaitlist(email);
        setUser(user);

      } catch (error: any) {
        setUser(undefined);
        setErrorMessage(error.message);
      }
    }
  }, [email, setUser]);

  useEffect(() => {
    fetchWaiters();
  }, [email, fetchWaiters]);

  const context = useAppContext();
  const navigate = useNavigate();

  const form = useForm<WaiterValue>({
    initialValues: user,

  });

  useEffect(() => {
    if (user) {
      form.setValues(user);
      form.resetDirty(user);
      // setErrorMessage(undefined);
      // setOkMessage(undefined);
    }
  }, [user]);

  const inviteWaiter = useCallback(async (updatedUser: WaiterValue) => {
    try {
      const result = await backend.current?.inviteWaiters([updatedUser.email]);
      if (result?.status === 'ok' && result.ninvited > 0) {
        setOkMessage("User with email " + updatedUser.email + " has been invited to the platform.");
        setErrorMessage(undefined);
        navigate('/waitlist/' + updatedUser.email + '/');
      } else {
        setOkMessage(undefined);
        setErrorMessage("Failed to invite user with email " + updatedUser.email + ". Does it already exist?");
      }

      // refetch user to get the updated version
      await fetchWaiters();
    } catch (error: any) {
      setOkMessage(undefined);
      setErrorMessage(error.message);
    }
  }, [user, errorMessage]);

  const deleteWaiter = useCallback(async (user: WaiterValue | undefined) => {
    const email = user?.email;

    if (!email) {
      return;
    }

    // show confirmation dialog
    if (!window.confirm(`Are you sure you want to remove ${user.email} from the waitlist ?`)) {
      return;
    }

    try {
      const result = await backend.current?.deleteWaiters([user.email]);
      setOkMessage(result.message);
      navigate('/waitlist');

    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [user, errorMessage]);

  const displayedCreatedAt = useMemo(() => {
    if (form.values.created_at) {
      return new Date(form.values.created_at).toISOString().split('T')[0];
    }
    return '';
  }, [form.values.created_at]);

  const displayedInvitedAt = useMemo(() => {
    if (form.values.invited_at) {
      return new Date(form.values.invited_at).toISOString().split('T')[0];
    }
    return '';
  }, [form.values.invited_at]);

  return <Page id={'user-admin'} showSubHeader={true}>
    <Container>
      <Box sx={{ width: '90%' }} mx="auto">
        {okMessage && <Alert title="OK" color="blue" withCloseButton onClose={() => setOkMessage(undefined)}>{okMessage}</Alert>}
        {errorMessage && <Alert title="Error" color="red" withCloseButton onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>}
        {!user && <h1> <FormattedMessage defaultMessage={'Select a user to edit'} /></h1>}
        {user && (
          <form onSubmit={form.onSubmit((user) => inviteWaiter(user))}>
            <Group position="right" mt="md">
              <Button type="submit">Invite</Button>
              <Button onClick={() => deleteWaiter(user)} disabled={!user?.email} >Delete</Button>
            </Group>
            <h2>Basic Info</h2>
            <Group position="left" mt="md">
              <TextInput {...form.getInputProps('email')} label="Email" required disabled />
              {/* <TextInput {...form.getInputProps('created_at')} label="Joined Waitlist" required disabled />
              <TextInput {...form.getInputProps('invited_at')} label="Invited" required disabled /> */}
              <TextInput {...form.getInputProps('created_at')} label="Joined Waitlist" required disabled value={displayedCreatedAt} />
              <TextInput {...form.getInputProps('invited_at')} label="Invited" disabled value={displayedInvitedAt} />
              {/* <List {...form.getInputProps('services')} label="Services" /> */}
            </Group>
          </form>
        )}
      </Box>
    </Container>
  </Page>;
}
