import styled from '@emotion/styled';
import { TextInput, Radio, Button, Group, Box, Switch, Textarea, Select, JsonInput, Image, FileInput, List, Avatar, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Text } from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Page } from '../page';
// import { useOption } from '../../core/options/use-option';
// import { isProxySupported } from '../../core/game/openai';
import { useAppContext } from '../../core/context';
// import Terms from './terms';
// import Privacy from './privacy';
// //for login button
import { backend } from '../../core/backend';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth0User } from '../../core/types';
// import MessageError from '../message-error';
// import { StoriesCarousel } from '../stories-carousel';
///

const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: ;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
    overflow-y: auto ; /* Enable vertical scrolling */
`;

export default function UserAdmin() {
  // get user name from the :name parameter
  const { id } = useParams<string>();
  // let version : string | undefined = undefined;

  // if name includes a version, split it
  // if (name && name.includes('/')) {
  //   const parts = name.split('/');
  //   name = parts[0];
  //   version = parts[1];
  // }

  // the user to edit with this specific version
  const [user, setUser] = useState<Auth0User | undefined>(undefined);
  const [roles, setRoles] = useState<string[]>([]);
  const [subscriptions, setSubscriptions] = useState<string>('');

  // the stories with the same name but various versions
  // const [users, setUsers] = useState<Map<string, User>>(new Map<string, User>());
  const [okMessage, setOkMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const fetchUsers = useCallback(async () => {
    setOkMessage(undefined);
    setErrorMessage(undefined);

    if (id) {
      // fetch specific user from backend
      try {
        const user = await backend.current?.getUser(id);
        setUser(user);

        const roles = await backend.current?.getUser(id, 'roles');
        setRoles(roles);

        const subscriptions = await backend.current?.getUserSubscriptions(id);
        setSubscriptions(subscriptions || '');

      } catch (error: any) {
        setUser(undefined);
        setErrorMessage(error.message);
      }
    }
  }, [id, setUser, setRoles, setSubscriptions]);

  useEffect(() => {
    fetchUsers();
  }, [id, fetchUsers]);

  const context = useAppContext();
  const navigate = useNavigate();

  const form = useForm<Auth0User>({
    initialValues: user,

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    if (user) {
      form.setValues(user);
      form.resetDirty(user);
      setErrorMessage(undefined);
      setOkMessage(undefined);
    }
  }, [user]);

  const updateUser = useCallback(async (updatedUser: Auth0User) => {
    try {
      const result = await backend.current?.updateUser(updatedUser);
      setOkMessage(result.message);
      navigate('/user/' + updatedUser.user_id + '/');

      // refetch user to get the updated version
      await fetchUsers();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [user, errorMessage]);

  const deleteUser = useCallback(async (user: Auth0User | undefined) => {
    const id = user?.user_id;

    if (!id) {
      return;
    }

    // show confirmation dialog
    if (!window.confirm(`Are you sure you want to purge the user ${user.name} with id ${user.user_id} ?`)) {
      return;
    }

    try {
      const result = await backend.current?.deleteUser(id);
      setOkMessage(result.message);
      navigate('/');

    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [user, errorMessage]);

  const userFirstLetter = useMemo(() => {
    if (!user) {
      return null;
    }
    
    return user.name ? user.name.charAt(0).toUpperCase() : 'X';
  }, [user]);

  return <Page id={'user-admin'} showSubHeader={true}>
    <Container>
      <Box sx={{ width: '90%' }} mx="auto">
        {okMessage && <Text>{okMessage}</Text>}
        {errorMessage && <Text>{errorMessage}</Text>}
        {!user && <h1> <FormattedMessage defaultMessage={'Select a user to edit'} /></h1>}
        {user && (
          <form onSubmit={form.onSubmit((user) => updateUser(user))}>
            <Group position="right" mt="md">
              <Button type="submit" disabled >Update</Button>
              <Button onClick={() => deleteUser(user)} disabled={!user?.user_id} >Delete</Button>
            </Group>
            <h2>Basic Info</h2>
            <Group position="left" mt="md">
              <Avatar
                src={user.picture}
                alt={user.name}
                size="lg"
                color="red"
              >{userFirstLetter}
              </Avatar>
              <TextInput {...form.getInputProps('user_id')} label="ID" required disabled />
              <TextInput {...form.getInputProps('name')} label="Name" required disabled />
              <TextInput {...form.getInputProps('email')} label="Email" required disabled />
              {/* <List {...form.getInputProps('services')} label="Services" /> */}
            </Group>
            <Space h="lg"/>
            <h2>Roles</h2>
            <Group>
            <List withPadding>
              {roles && roles.length > 0 && roles.map((role: any) => (
                <List.Item>{role.name}</List.Item>
              ))}
              {(!roles || roles.length == 0) && <List.Item>None</List.Item>}
            </List>
            </Group>
            <Space h="lg"/>
            <h2>Subscriptions</h2>
            <Group>
              <JsonInput 
                id="subscriptions"
                value={subscriptions}
                minRows={5}
                formatOnBlur
                autosize
                onChange={(value) => setSubscriptions(subscriptions)}
                style={{ width: '100%' }}
              />
            </Group>
          </form>
        )}
      </Box>
    </Container>
  </Page>;
}
